.host {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 80px;
  margin: auto;

  line-height: 80px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff;

  opacity: 0.9;
  animation: fadeout 0.2s ease-in 1s forwards;
}
.host.blue {
  background-image: linear-gradient(90deg, #2e7bce 0%, #24b291 100%);
}
.host.orange {
  background-image: linear-gradient(90deg, #e83c3c 0%, #eaa822 100%);
}

@keyframes fadeout {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}
