.host {
  width: 100%;
  height: 100%;

  background: var(--background-darkblue);
}

.item {
  display: block;
  height: 60px;
  padding: 0 6px;

  border-left: solid 6px transparent;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;

  display: flex;
  align-items: center;
}
.item > * + * {
  margin-left: 8px;
}
.item.active {
  background: var(--background-gray);
  color: var(--background-darkblue);
  border-left-color: var(--background-mainblue);
}
