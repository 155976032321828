.host {
}

.writable {
  padding: 40px 80px 60px 40px;
  background: #fff;
  border: 1px solid #ccc;
}

.formGroup {
  display: flex;
}
.formGroup > * {
  flex: 1 1 auto;
}
.formGroup + .formGroup {
  margin-top: 20px;
}

.labelText {
  width: 160px;
  line-height: 44px;
  flex: 0 0 auto;
}

.dateWave {
  display: inline-block;
  width: 42px;
  font-size: 14px;
  color: #5b5b5b;
  text-align: center;
}

.invalid {
  padding: 8px 10px;
  font-weight: bold;
  color: #ec4a4a;
}

.choice + .choice {
  margin-left: 20px;
}

.footer {
  padding: 12px 0;

  display: flex;
  align-items: center;
}
.footer > * + * {
  margin-left: 8px;
}
.spacer {
  flex: 1 1 auto;
}

.deleteButton,
.cancelButton,
.submitButton {
  width: 220px;
}

.deleteButton {
  background: #ff5151;
}

.cancelButton {
  background: #8193a6;
}
