.host {
  appearance: none;
  border: solid 1px #a1adb4;
  min-width: 100px;
  height: 44px;
  padding: 10px;
  line-height: 24px;
  border-radius: 4px;
  background: #fafafa;
  font-size: 15px;
}

.host.invalid {
  background: #fedede;
  border-color: #ec4a4a;
}

.unmaskableInput {
  position: relative;
}
.host.unmaskable {
  padding-right: 40px;
  width: 100%;
}
.unmaskButton {
  position: absolute;
  right: 6px;
  bottom: 6px;

  background: transparent;
  height: 32px;
  width: 32px;
}
.unmaskButton > svg {
  margin: 0 !important;
}
