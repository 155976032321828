/**
 * CSS Reset
 * @see https://create-react-app.dev/docs/adding-css-reset
 */
@import-sanitize;

html {
  /* 最小画面幅 (19インチスクエア) */
  min-width: 1280px;
  background: var(--background-gray);
  font-size: 15px;
  font-family: system-ui, -apple-system, "Hiragino Sans", "Yu Gothic UI",
    sans-serif;
}

html,
body,
#root {
  height: 100%;
}

#flash-message {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: var(--layer-overlay);
}

a {
  color: #2e7bce;
  text-underline-position: under;
}

/** アプリケーション全体に関連する変数 */
:root {
  /* color */
  --background-darkblue: #104166;
  --background-mainblue: #1573d7;
  --background-gray: #f5f5f7;
  --background-list-title-gray: #63768a;
  --background-table-gray: #ccd9df;
  --font-gray: #919fad;
  --font-gray2: #888;
  --font-white: #fff;
  --border-gray: #ccc;
  --form-red: #ec4a4a;
  --button-red: #ff5151;
  --button-gray: #8193a6;
  --button-disable-gray: #e9e9ef;
  /* z-index */
  --layer-layout-frame: 1;
  --layer-header: 2;
  --layer-modal: 3;
  --layer-overlay: 4;
}
