.table caption>span+span {
  margin-left: 20px;
}

/**
 * 各列の幅の設定
 */
.userIdColumn {
  width: 40%;
  text-align: left;
}

.userNameColumn {
  width: 20%;
  text-align: left;
  border-left: 1px solid var(--background-table-gray);
}

.belongToColumn {
  width: 40%;
  text-align: left;
  border-left: 1px solid var(--background-table-gray);
}

.consentDateColumn {
  white-space: nowrap;
  text-align: left;
  border-left: 1px solid var(--background-table-gray);
}
