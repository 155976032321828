.link,
.ellipsis,
.space {
  width: 36px;
  height: 36px;
  white-space: nowrap;

  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.link {
  color: #2e7bce;
  text-decoration: none;
  background: #fff;
  border: 1px solid #ccd9df;
  border-radius: 4px;
}
.link.active {
  background: #bdc8cd;
  color: #fff;
}

.ellipsis {
  color: #adadad;
}

.space {
  visibility: hidden;
}
