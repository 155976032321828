.host,
.menuItem {
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  padding: 0;
}

.host {
  height: 60px;
  padding: 0 4px;
  color: #fff;

  display: flex;
  align-items: center;
  outline: none;
}
.host > * + * {
  margin-left: 8px;
}

.menu {
  position: absolute;
  top: 60px;
  right: 0;
  width: 200px;
}

.menuItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 60px;
  padding: 0 16px;
  background: #2e7bce;
  color: #fff;
}
.menuItem > * + * {
  margin-left: 8px;
}
