.form {
  width: 100%;
  padding: 0 30px;
}
.form > * + * {
  margin-top: 20px;
}

.form svg {
  margin-bottom: 30px;
}

.submit {
  width: 100%;
  height: 55px;
  margin-top: 30px;
}
