.writable {
  padding: 40px 80px 60px 40px;
  background: var(--font-white);
  border: 1px solid var(--border-gray);
}

.formGroup:not([hidden]) {
  display: flex;
}
.formGroup > * {
  flex: 1 1 auto;
}
.formGroup + .formGroup {
  margin-top: 20px;
}

.labelText {
  width: 160px;
  line-height: 44px;
  flex: 0 0 auto;
}

.invalid {
  margin: 8px 12px 20px 174px;
  font-size: 15px;
  font-weight: bold;
  color: var(--form-red);
}

.choice + .choice {
  margin-left: 20px;
}

.footer {
  padding: 12px 0;

  display: flex;
  align-items: center;
}
.footer > * + * {
  margin-left: 8px;
}
.spacer {
  flex: 1 1 auto;
}

.deleteButton,
.cancelButton,
.submitButton {
  width: 220px;
}

.deleteButton {
  background: var(--button-red);
}
.deleteButton:disabled {
  background: var(--button-disable-gray);
}

.cancelButton {
  background: var(--button-gray);
}

.innerGroup span {
  width: 100px;
}
