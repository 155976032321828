.host {
  padding: 70px 40px 60px;
  color: #919fad;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background-gray);
}

.title {
  margin-top: 32px;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.title > * + * {
  margin-left: 10px;
}

.subTitle {
  margin-top: 24px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.message {
  margin-top: 30px;
  padding: 20px 30px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  background-color: #e9ebed;
  min-width: 640px;
}
