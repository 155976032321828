.host {
  height: 48px;
  border-radius: 4px;

  border: none;
  padding: 0;
  cursor: pointer;
  background: #1573d7;

  font-family: inherit;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}
.host:disabled {
  background: #e9e9ef;
  color: rgba(16, 65, 102, 0.1);
}
