.host {
  width: 100%;
  height: 100%;
  padding: 0 20px;

  background: url("./img_login_header_background.jpg") center/cover, #0f4d99;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

  display: flex;
  justify-content: space-between;
  align-items: center;
}
