.ahead {
  padding: 12px 0 12px 10px;

  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.ahead > * + * {
  margin-left: 20px;
}

.timestamps {
  color: #888;

  display: grid;
  grid-template-columns: auto auto;
  gap: 8px 20px;
}
