.table {
  margin-top: 46px;
}

/**
 * 各列の幅の設定
 */
.titleColumn {
  width: 100%;
  text-align: left;
  border-left: 1px solid var(--background-table-gray);
}

.dateColumn,
.consentRateColumn {
  white-space: nowrap;
  text-align: left;
  border-left: 1px solid var(--background-table-gray);
}

.idColumn {
  white-space: nowrap;
  text-align: center;
}
