.host {
  width: 100%;
}

.host caption {
  padding: 12px 10px;
  font-size: 15px;
  text-align: left;
  color: #888;
  height: 46px;
}

.host thead {
  color: #fff;
  background: var(--background-list-title-gray);
}

.host thead tr {
  height: 40px;

  font-weight: bold;
}

.host thead th {
  padding: 8px 20px;
}

.host tbody tr {
  height: 60px;
}

.host tbody td {
  padding: 8px 20px;
}

.host tbody tr:nth-child(odd) {
  background: #fff;
}

.host tbody tr:nth-child(even) {
  background: var(--background-gray);
}
