.host {
  display: inline-block;
}

.textarea {
  display: block;
  width: 100%;

  border: solid 1px #a1adb4;
  min-height: 44px;
  height: 240px;
  padding: 10px;
  line-height: 24px;
  border-radius: 4px;
  background: #fafafa;
  font-size: 15px;
}

.textarea.invalid {
  background: #fedede;
  border-color: #ec4a4a;
}

.counter {
  margin-top: 8px;
  text-align: right;
}
.counter.zero {
  color: #888;
}
