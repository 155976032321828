.host {
  height: 100px;
  margin: auto;
  padding: 24px;

  font-size: 28px;
  font-weight: bold;

  display: flex;
  align-items: center;
}
.host > * + * {
  margin-left: 12px;
}
