.host {
  background-color: var(--background-gray03);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: #919fad;
}

.title {
  font-size: 28px;
  font-weight: bold;
  margin-top: 32px;
}

.message {
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
}
