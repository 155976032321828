.host {
  /* デフォルトの見た目をリセット */
  appearance: none;
  vertical-align: middle;
  border: 0;

  display: inline-block;
  width: 24px;
  height: 24px;
  margin: 8px;
  background-position: center;
  background-image: url("./ic_40_radiobox_radiobox_off.svg");
}
.host:checked {
  background-image: url("./ic_40_radiobox_radiobox_on.svg");
}
