.timestamps {
  padding: 12px 10px;
  color: #888;

  display: flex;
  justify-content: flex-start;
}
.timestamps > * + * {
  margin-left: 20px;
}
