th:not(:last-child),
td:not(:last-child) {
  border-right: 1px solid var(--background-table-gray);
}

/**
 * 各列の幅の設定
 */
.organizationColumn {
  width: 50%;
  text-align: left;
}
.countryNameColumn {
  width: 50%;
  text-align: left;
}
