.termsTitle {
  font-size: 24px;
  font-weight: normal;
  margin: 8px 10px 2px;
}

.tableView {
  position: relative;
}

.pager {
  margin: 40px auto 10px;
}
