.host {
  display: block;
  height: 60px;
  padding: 0 6px;

  border-left: solid 6px transparent;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;

  display: flex;
  align-items: center;
}

.text {
  margin: 0 8px;
}
