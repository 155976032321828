.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 60px;
  z-index: var(--layer-header);
}

.main {
  height: 100%;
  padding-top: 60px;
  overflow: auto;
}
