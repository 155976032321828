.conditions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 8px;
  grid-column-gap: 40px;
  padding: 0 10px;
}

.formGroup {
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.formGroup > * {
  flex: 1 1 auto;
  width: 100px;
}
.labelText {
  width: 90px;
  flex: 0 0 auto;
}

.status + .status {
  margin-left: 10px;
}
.status > * {
  vertical-align: middle;
}

.buttons {
  padding: 12px 0 20px;
  text-align: center;
}

.searchButton {
  width: 220px;
}
