.pager {
  margin: 40px auto 10px;
}

.tableView {
  position: relative;
}

.newOrganizationButton {
  position: absolute;
  top: 0;
  right: 0;

  height: 36px;
  border-radius: 4px;
  padding: 0 14px 0 6px;

  border: none;
  cursor: pointer;
  background: var(--background-mainblue);

  font-family: inherit;
  font-size: 15px;
  font-weight: bold;
  color: var(--font-white);

  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.noSearchResult {
  margin: 40px auto;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: var(--font-gray);
}
