.host {
  width: 100%;
  min-height: 100%;
  padding: 0;

  display: flex;
  justify-content: flex-end;
  align-items: stretch;
}

.leftBox {
  display: flex;
  justify-content: center;
  width: 60%;
  background-repeat: no-repeat;
  background-image: url("img_login_message.svg"),
    url("img_login_background.jpg");
  background-position: center, center;
  background-size: auto, cover;
  background-origin: border-box, border-box;
}

.rightBox {
  display: flex;
  justify-content: center;
  width: 40%;
  background: #f4f4f6;
}

.innerbox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 430px;
}
