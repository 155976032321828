tr.disabled {
  color: #ccc;
}

th:not(:last-child),
td:not(:last-child) {
  border-right: 1px solid #ccd9df;
}

/**
 * 各列の幅の設定
 */
.userIdColumn {
  width: 25%;
  text-align: left;
}
.organizationColumn {
  width: 20%;
  text-align: left;
}
.roleColumn,
.statusColumn {
  width: 10%;
  white-space: nowrap;
  text-align: left;
}
.registrationDateColumn,
.lastAccessColumn {
  width: 20%;
  white-space: nowrap;
  text-align: left;
}
