.pager {
  margin: 40px auto 10px;
}

.tableAhead {
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-end;
}

.newButton {
  height: 36px;
  border-radius: 4px;
  padding: 0 14px 0 6px;

  border: none;
  cursor: pointer;
  background: #1573d7;

  font-family: inherit;
  font-size: 15px;
  font-weight: bold;
  color: #fff;

  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

tr.disabled {
  color: #ccc;
}

.noSearchResult {
  margin: 40px auto;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #919fad;
}

/**
 * 各列の幅の設定
 */
.titleColumn {
  width: 40%;
  text-align: left;
}

.detailColumn {
  width: 60%;
  text-align: left;
  border-left: 1px solid var(--background-table-gray);
}

.publicationPeriodColumn,
.statusColumn,
.displayColumn {
  white-space: nowrap;
  text-align: left;
  border-left: 1px solid var(--background-table-gray);
}
