.sidebar {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: 0;
  width: 180px;
  z-index: var(--layer-layout-frame);
}

.main {
  padding-left: 180px;
  overflow: auto;
}
