.host {
  display: block;
  height: 60px;
  padding: 0 6px;

  border-left: solid 6px transparent;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;

  display: flex;
  align-items: center;
}
.host.active {
  background: var(--background-gray);
  color: var(--background-darkblue);
  border-left-color: var(--background-mainblue);
}

.active .icon,
.activeIcon {
  display: none;
}
.active .activeIcon {
  display: inline;
}

.text {
  margin-left: 8px;
}
