.footer {
  padding: 12px 0;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.footer > * + * {
  margin-left: 8px;
}

.cancelButton,
.submitButton {
  width: 220px;
}

.cancelButton {
  background: #8193a6;
}
